@import "../../../Styles/app.scss";

.main-container {
    font-family: $family;
  display: flex;
  flex-direction: column;
  background-color: $lightBlue;
  border-radius: 20px;
  padding: 20px;
  border: 3px solid $blue;
  font-family: $family;
  // width: 100% !important;
  h2 {
    text-align: center;
    font-family: $family;
    color: $blue;
    line-height: 40px;
  }
  .inner-container {
    padding: 50px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    .card {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 20px;
      margin: 10px;
      border: 2px solid $blue;
      border-radius: 10px;
      width: 40%;
      h4{
        margin: 10px;
      }
      p{
        color: $purple;

        margin: 10px;
        font-weight: 700;
      }
    }
  }
}
#vertical-tabpanel-4 {
  width: 86%;
}

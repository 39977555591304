@import "../../../../../Styles/app.scss";

.selfsinglechapter_container {
  .selfsinglechapter_container_heading {
    font-size: 23px;
    font-weight: 700;
    color: $black;
    text-transform: capitalize;
    font-family: $family;
    margin-bottom: 10px;
    margin-top: 10px;
  }
  .selfsinglechapter_container_heading_num {
    font-size: 18px;
    font-weight: 400;
    margin-left: 6px;
  }
  .selfsinglechapter_container_heading_lessons {
    display: flex;
    flex-direction: column;
    color: Gray;
    font-size: 17px;
    font-family: $family;
    margin-left: 0px !important;
    margin-top: 4px;
    list-style: circle;

    .selfsinglechapter_container_lesson {
      cursor: pointer;
      display: flex;
      gap: 10px;
      align-items: center;
      margin: 4px 0px;
      .selfsinglechapter_container_lesson_name {
        text-transform: capitalize;
        color: gray;
      }
      .selfsinglechapter_container_lesson_score {
        color: $lightGray;
        background-color: $blue;
        font-size: 10px;
        padding: 5px;
        border-radius: 5px;
      }
    }
  }
}

.self_modal {
  display: flex;
  flex-direction: column;
  .self_modal_header {
    width: 100%;
    height: 300px;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
  }
  .self_modal_body {
    display: flex;
    flex-direction: column;
  }
  .self_modal_body_length {
    font-size: 27px;
  }
  .self_modal_body_play {
    font-size: 27px;
    background-color: $blue;
    color: $lightGray;
    border-radius: 10px;
    padding-left: 20px;
    font-weight: 600;
  }
}

.ant-modal-footer {
  display: none;
}

a:hover {
  text-decoration: none;
}

.green {
  background-color: rgba(0, 128, 0, 0.61) !important;
  width: 40px;
  text-align: center;
}
.red {
  background-color: rgba(255, 0, 0, 0.521) !important ;
  width: 40px;
  text-align: center;
}
.orange {
  background-color: rgb(224, 131, 9) !important;
  width: 40px;
  text-align: center;
}

@import "../../../Styles/app.scss";
.testimonial_container {
  border: 2px solid $lightBlue;
  border-radius: 20px;
  display: flex;
  padding: 30px 15px;
  gap: 10px;
  margin: 20px;
  transition: 0.3s;
  cursor: pointer;
  &:hover {
    background-color: $blue;
    color: $lightBlue;
  }
  &:hover .testimonial_container_right_text {
    color: $lightBlue;
  }
  &:hover .testimonial_container_right_line {
    background-color: $lightGray;
  }
  &:hover .testimonial_container_right_footer_right_name {
    color: $lightBlue;
  }
  .testimonial_container_quote {
    .testimonial_container_quote_icon {
      width: 30px;
      height: 30px;
      padding: 5px;
      font-size: 20px;
      background-color: $blue;
      border-radius: 50%;
      color: $lightGray;
    }
  }
  .testimonial_container_right {
    .testimonial_container_right_text {
      font-size: 20px;
      //   color: $black;
      font-weight: 500;
      height: 160px;
    }
    .testimonial_container_right_line {
      width: 100%;
      // height: 3px;
      background-color: $blue;
      margin: 30px 0px;
    }
    .testimonial_container_right_footer {
      display: flex;
      gap: 20px;
      .testimonial_container_right_footer_profile {
        .testimonial_container_right_footer_profile_img {
          width: 80px;
          height: 80px;
          border-radius: 50%;
        }
      }
      .testimonial_container_right_footer_right {
        display: flex;
        flex-direction: column;
        gap: 20px;
      }
    }
  }
}

.quote_right{
  display: flex;
  justify-content: flex-end;
}
.quote_right_icon {
  transform: scale(-1);
}

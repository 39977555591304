 @import "../../Styles/app.scss";
.header {
  width: 100%;
  background: #d9d9d9;
  display: flex;
  justify-content: space-between;
  font-family: "Inter";
  .search-bar {
    display: flex;
    align-items: center;
    background-color: #fff;
    border-radius: 20px;
    height: 35px;
    text-align: center;
    cursor: pointer;
  
    .search-text {
      text-align: center;
      width: 90%;
      height: 35px;
      font-size: 14px;
      color: rgba(0, 0, 0, 0.66);
      border-radius: 20px;
      align-items: center;
    }
   
  }
  

  P {
    font-style: normal;
    font-weight: 800;
    font-size: 18px;
    line-height: 22px;

    color: rgba(0, 0, 0, 0.66);
  }
  p:hover{color: $blue;}

  .nav {
    height: 70px;
    align-items: center;
    display: flex;
    width: 100%;
    justify-content: space-between;
    h2 {
      color: #a575e3;
      margin-left: 20px;
      font-style: normal;
      font-weight: 700;
      font-size: 25px;
    }
    .left-side {
      display: flex;
      justify-content: space-around;
      width: 40%;
      align-items: center;
      @media only screen and (max-width: "1023px") {
        display: none;
      }
    }
    .right-side {
      width: 40%;
      display: flex;
      align-items: center;
      justify-content: space-around;
      @media only screen and (max-width: "1023px") {
        display: none;
      }
    }
  }
  .hamburger-menu {
    position: relative;
    top: 20px;
    right: 20px;
    font-size: 2rem;
    display: none;
  }
  .hamburger-menu:hover{
    color: $blue;
    cursor: pointer;
  }

  @media only screen and (max-width: "1023px") {
    .hamburger-menu {
      display: block;
    }
  }

  .nav-2 {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    h2 {
      margin-top: 20px;
      color: #7d28ea;
      margin-left: 20px;
      font-style: normal;
      font-weight: 700;
      font-size: 25px;
      line-height: 30px;
    }
    p {
      margin: 10px;
    }
    p:hover{color: $blue;}


    .left-side {
      align-items: center;
      display: flex;
      flex-direction: column;
      p {
        margin: 10px;
      }
      p:hover{color: $blue;}
    }

    .right-side {
      align-items: center;
      display: flex;
      flex-direction: column;
      p {
        margin: 10px;
      }
      p:hover{
        color: $blue;
      }
    }
  }
}

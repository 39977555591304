@import "../../../Styles/app.scss";
.explore_container {
  flex: 1;
  height: 100vh;
  background-color: $lightGray;
  padding: 50px;
  display: flex;
  flex-direction: column;
  position: relative;
  @include xtablet{
    width: 100%;
  }
  .explore_container_header {
    font-size: 30px;
    font-weight: 700;
    font-family: $family;
    margin-bottom: 100px;
    .explore_sidebar_title_menu {
      font-size: 30px;
      margin-top: 20px;
      display: none;
      position: absolute;
      left: 20px;
      top: 20px;
      cursor: pointer;
      @include xtablet {
        display: block;
      }
    }
  }
  .explore_container_body {
    display: flex;
    flex-direction: column;
    gap: 50px;
    justify-content: center;
    align-items: center;
  }
  .explore_container_title {
    font-size: 40px;
    font-family: $family;
    margin: 10px auto 0px auto;
    font-weight: 500;
    text-align: center;
  }
  .explore_container_des {
    color: gray;
    font-size: 20px;
    font-family: $family;
    margin: 0px auto 10px auto;
  }
  .explore_search-bar {
    display: flex;
    align-items: center;
    background-color: $lightBlue;
    border-radius: 40px;
    padding: 5px;
    width: 60%;
    margin-top: 10px;
    cursor: pointer;
    @include mobile {
      width: 100%;
    }
  }

  .explore_search-input-container {
    display: flex;
    align-items: center;

    border-radius: 40px;
    padding: 5px;
    width: 93%;
    @include mobile {
      width: 70%;
    }
  }

  .explore_search-input {
    flex: 1;
    height: 30px;
    border: none;
    padding-left: 5px;
    font-size: 16px;
    background-color: $lightBlue;
    @include mobile {
      width: 60%;
    }
  }

  .explore_search-icon {
    margin-right: 5px;
    color: $blue;
    margin-left: 10px;
    font-size: 20px;
  }
  .explore_btn-icon {
    margin-right: 5px;
    margin-top: 5px;
    font-size: 14px;
    margin-left: 15px;
  }

  .explore_search-button {
    margin-left: 10px;
    background-color: $blue;
    border: none;
    color: white;
    width: 100px;
    height: 40px;
    font-size: 1rem;
    margin-left: 5px;
    border-radius: 20px;
    cursor: pointer;
  }
  .explore_search-button:hover {
    background-color: darken($color: $blue, $amount: 10);
  }

  .suggestions_container {
    margin: auto;
    width: 80%;
    display: flex;
    flex-direction: column;

    background-color: $lightBlue;
    border-radius: 6px;
    box-shadow: 0px 0px 3px rgb(80, 77, 77);
    .suggestions_container_item {
      color: $black;
      display: flex;
      align-items: center;

      padding: 10px;
      &:hover {
        background-color: $lightGray;
      }
      .suggestions_container_item_icon {
        color: $black;
        margin-right: 20px;
        margin-left: 10px;
      }
    }
  }
}
@import "../../../Styles/app.scss";

.teacher-quiz-main-div {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  flex: 1;
  padding: 20px;
  background-color: #fff;
  font-family: $family;
  @include xtablet {
    width: 100%;
  }
  .loading-toast {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.5);
    color: #fff;
    padding: 16px;
    font-size: 18px;
    border-radius: 4px;
    z-index: 9999;
  }
  
  .blur-page {
    filter: blur(4px);
    pointer-events: none;
  }
  .teacher-create-quiz-top-bar {
    display: flex;
    margin: 10px 30px;
    justify-content: space-between;
    align-items: center;
    @include xtablet {
      width: 100%;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      margin: 0px;
    }
    .A-h2 {
      flex: 1;
      font-size: 40px;

      font-weight: 600;
      @include xtablet {
        font-size: 30px;
        gap: 10px;
        display: flex;
        align-items: center;
      }
    }
    .top-bar-btns {
      flex: 1;
      display: flex;
      justify-content: flex-end;
      .select-folder {
        background-color: #f0f2ff;
        padding: 7px 18px;
        border-radius: 50px;
        color: #33333d;
        appearance: none;
        -moz-appearance: none;
        -webkit-appearance: none;
        border: none;
        margin-left: 7px;

        outline: none;
      }
      .select-folder:hover {
        background-color: #5272fb;
        color: #dce3f0;
      }
      .top-bar-btn-private {
        background-color: #f0f2ff;
        padding: 7px 18px;
        border-radius: 50px;
        color: #33333d;
        border: none;
        outline: none;
        margin-left: 7px;
      }
      .top-bar-btn-private:hover {
        background-color: #5272fb;
        color: #dce3f0;
      }
    }
  }

  .form-1 {
    margin: 10px 30px;
    display: flex;
    flex-direction: column;

    @include xtablet {
      margin: 0px;
      width: 100%;
    }
    .div-1 {
      @include xtablet {
        width: 100%;
      }
    }
    .main-selects {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      @include xtablet {
        width: 100%;
      }
      .select-subjects {
        display: flex;
        flex-direction: column;
        @include xtablet {
          width: 100%;
        }
        .selectbook-title {
          color: $black;
          font-size: 16px;
          font-weight: 500;
          margin: 5px 0px;
        }
        .book-name {
          margin: 15px 0px;
          @include xtablet {
            margin: 0px;
            display: flex;
            flex-wrap: wrap;
          }
          span {
            margin: 5px;
            font-weight: 300;
            padding: 7px 15px;
            background-color: #f0f2ff;
            font-size: 17px;
            cursor: pointer;
          }
          span:hover {
            background-color: #5272fb;
            color: #dce3f0;
          }
          .selected {
            background-color: #5272fb;
            color: #dce3f0;
          }
        }
      }
    }
    .grade {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      @include xtablet {
        width: 100%;
      }
      .grad-title {
        color: $black;
        font-size: 16px;
        font-weight: 500;
        margin: 5px 0px;
      }
      .grades-names {
        display: flex;
        margin: 5px 0;
        flex-wrap: wrap;
        .opt-label {
          margin: 5px;
          font-weight: 300;
          padding: 7px 15px;
          background-color: #f0f2ff;
          font-size: 17px;
          cursor: pointer;
        }
        .opt-label:hover {
          background-color: #5272fb;
          color: #dce3f0;
        }
        .selected-grd {
          border: 1px solid $blue;
          background-color: #5272fb;
          color: #dce3f0;
        }
        input {
          all: unset;
          border: none;
          outline: none;
        }
      }
    }
    .div-3 {
      display: flex;
      flex-direction: row;
      margin-top: 15px;
      @include xtablet {
        width: 100%;
        gap: 10px;
      }
      .enter-lesson {
        display: flex;
        flex-direction: column;
        margin: 5px;
        margin-top: 10px;
        flex-wrap: wrap;
        @include xtablet {
          width: 50%;
          margin: 0px;
        }

        p {
          font-size: 18px;
          font-weight: 500;
        }
        textarea {
          margin-top: 5px;
          width: 300px;
          height: 170px;
          background-color: #f0f2ff;
          resize: none;
          padding-top: 20%;
          padding-left: 10px;
          padding-right: 10px;
          overflow: hidden;
          border: none;
          outline: none;
          text-align: center;
          font-size: 16px;
          display: table;
          margin: 0 auto;
          @include xtablet {
            width: 100%;
            margin: 0px;
            padding: 0px;
          }
        }
      }
      .select-lesson-image {
        margin-left: 30px;
        width: 200px;
        display: flex;
        flex-direction: column;
        @include xtablet {
          width: 50%;
          margin: 0px;
          padding: 0px;
        }
        .img-cover {
          display: flex;
          justify-content: space-between;
          p {
            font-size: 17px;
            font-weight: 500;
          }
          label {
            margin-left: 30px;
            background-color: #f0f2ff;
            padding: 5px 18px;
            border-radius: 50px;
            font-size: 14px;
            color: #33333d;
            border: none;
            outline: none;
            margin-left: 7px;
          }
          label:hover {
            background-color: #5272fb;
            color: #dce3f0;
          }
        }
        input {
          display: none;
        }
        .show-lesson-image {
          margin-top: 5px;
          // background-image: url("../../../images/imagepng.jpg");
          background-color: #f0f2ff;
          width: 200px;
          height: 170px;
          background-repeat: no-repeat;
          background-size: 50% auto; /* Adjust the percentage as needed */
          background-position: center;
          @include xtablet {
            width: 100%;
            margin: 0px;
          }
        }
      }
    }
    .show_info-new {
      width: 58%;
      margin-top: 10px;
      margin-left: 5px;
      padding: 20px;
      flex-wrap: wrap;
      display: flex;

      justify-content: center;

      background-color: #f0f2ff;
      @include xtablet {
        width: 100%;
      }
      .total-q {
        margin: 10px;
        flex: 1;
        justify-content: center;
        display: flex;
        flex-direction: column;
        text-align: center;

        .p-1 {
          margin: 10px;
          font-size: 20px;
          font-weight: 700;
        }
        .p-2 {
          margin: 10px;
          font-size: 18px;
        }
      }
      .total-d {
        margin: 10px;
        flex: 1;
        justify-content: center;
        display: flex;
        flex-direction: column;
        text-align: center;

        .p-1 {
          margin: 10px;
          font-size: 20px;
          font-weight: 700;
        }
        .p-2 {
          margin: 10px;
          font-size: 18px;
        }
      }
      .total-p {
        margin: 10px;
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;

        .p-1 {
          margin: 10px;
          font-size: 20px;
          font-weight: 700;
        }
        .p-2 {
          margin: 10px;
          font-size: 18px;
        }
      }
    }
    .settings-points {
      margin-top: 15px;
      width: 55%;
      padding: 18px;
      align-items: center;
      display: flex;
      justify-content: space-between;
      @include xtablet {
        width: 100%;
        margin: 0px;
        padding: 0px;
        margin-top: 10px;
      }
      .select-btns {
        display: flex;
        align-items: center;
        .s-all-btn {
          margin-right: 10px;
          background-color: #f0f2ff;
          padding: 5px 25px;
        }
        .s-all-btn:hover {
          background-color: white;
          border: 2px solid blue;
          color: #5272fb;
        }
        .s-duration {
          margin-top: 5px;
          display: flex;
          margin-right: 10px;
          flex-direction: column;
          select {
            background-color: #f0f2ff;
            padding: 8px 18px;
            font-size: 14px;
            color: #33333d;
            appearance: none;
            -moz-appearance: none;
            -webkit-appearance: none;
            border: none;
            margin-left: 7px;
            height: 34px;

            outline: none;
          }
          p {
            text-align: center;
            margin-top: 3px;
            font-size: 8px;
          }
        }
      }
    }

    .modal {
      display: flex;
      @include xtablet {
        width: 100%;
        flex-wrap: wrap;
      }
      .btn-ab-1 {
        background-color: #f0f2ff;
        padding: 7px 18px;
        border-radius: 50px;
        color: #33333d;
        border: none;
        outline: none;
        margin-left: 7px;
      }
      .btn-ab-1:hover {
        background-color: #5272fb;
        color: #dce3f0;
      }
    }
    .correct_answers {
      width: 100%;
    }
    .add-q-title {
      font-size: 22px;
      margin: 10px;
      font-weight: 500;
      @include xtablet {
        width: 100%;
      }
    }
    .show-question-div {
      background-color: #f0f2ff;
      padding: 20px;
      margin-top: 20px;
      display: flex;
      flex-direction: column;
      .inner-question {
        display: flex;
        justify-content: space-between;
        .show-q-name {
          font-family: $family;
          color: black;
          line-height: 40px;
          font-size: 20px;
          font-weight: 700;
        }
        input {
          width: 30px;
          height: 20px;
        }
      }
      .line {
        width: 95%;
        align-items: center;
        height: 3px;
        border: 50px;
        background-color: rgb(214, 211, 211);
        margin: 10px 0px;
      }
    }
    .end-div-btn {
      display: flex;
      justify-content: flex-end;
      margin-top: 20px;
    }

    .btnn {
      background-color: $blue;
      color: #fff;
      padding: 10px 20px;
      border: none;
      border-radius: 10px;
      cursor: pointer;
      font-size: 16px;
      margin-right: 20px;
    }

    .btnn:hover {
      background-color: blue;
    }

    .btnn:last-child {
      margin-right: 0;
    }
  }
}
#vertical-tabpanel-2 {
  width: 85%;
}
.select {
  margin-left: 10px;
  height: 30px;
  border-radius: 5px;
  font-size: 16px;
}
.ant-modal-content {
  width: 100% !important;
}

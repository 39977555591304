@import "../../../Styles/app.scss";

.coderoom_created {
  width: 80%;
  margin: auto;
  @include xtablet {
    width: 100%;
    padding: 20px;
  }
  .coderoom_explore_search-bar {
    display: flex;
    align-items: center;
    background-color: $lightBlue;
    border-radius: 40px;
    padding: 5px;

    width: 80%;
    margin-top: 10px;
    margin-left: auto;
    margin-right: auto;
    cursor: pointer;
    @include mobile {
      width: 100%;
    }
  }

  .coderoom_explore_search-input-container {
    display: flex;
    align-items: center;

    border-radius: 40px;
    padding: 5px;
    width: 93%;
    @include mobile {
      width: 70%;
    }
  }

  .coderoom_explore_search-input {
    flex: 1;
    height: 30px;
    border: none;
    padding-left: 5px;
    font-size: 16px;
    background-color: $lightBlue;
    @include mobile {
      width: 60%;
    }
  }

  .coderoom_explore_search-icon {
    margin-right: 5px;
    color: $blue;
    margin-left: 10px;
    font-size: 20px;
  }
  .coderoom_explore_btn-icon {
    margin-right: 5px;
    margin-top: 5px;
    font-size: 14px;
    margin-left: 15px;
  }

  .coderoom_explore_search-button {
    margin-left: 10px;
    background-color: $blue;
    border: none;
    color: white;
    width: 100px;
    height: 40px;
    font-size: 1rem;
    margin-left: 5px;
    border-radius: 20px;
    cursor: pointer;
  }
  .coderoom_explore_search-button:hover {
    background-color: darken($color: $blue, $amount: 10);
  }
  .coderoom_left-side-container {
    display: flex;
    flex-direction: column;

    margin-left: auto;
    margin-right: auto;
    width: 80%;
    @include tablet {
      width: 100%;
    }
    @include mobile {
      width: 100%;
    }
    .coderoom_total-results {
      width: 100%;
      height: 10px;
      align-items: center;
      display: flex;
      font-size: 16px;
      font-weight: 700;
      margin: 40px 0px 20px 0px;
    }

    .coderoom_result-container {
      margin-top: 10px;
      width: 100%;
      // padding: 10px;

      border-radius: 3px;
      display: flex;
      gap: 20px;
      flex-direction: column;
      height: 80vh;
      overflow-y: auto;
      .coderoom_result-card-main {
        margin: 5px;
        border-radius: 40px;
        padding: 15px;
        width: 100%;
        background-color: $lightBlue;
        display: flex;
        // box-shadow: 0px 0px 8px rgba(177, 174, 174, 0.coderoom_8);
        position: relative;
        margin-right: auto;
        margin-left: auto;
        @include xtablet {
          flex-direction: column;
          flex-wrap: wrap;
          height: auto;
          padding: 5px;
        }

        .coderoom_div-img {
          height: 80px;
          width: 80px;
          overflow: hidden;
          background-color: $lightBlue;
          border-radius: 20px;

          @include tablet {
          }
          .coderoom_lesson-img {
            width: 100%;
            object-fit: cover;
            height: 100%;
          }
        }
        .coderoom_coderoom_created_item_det_dots {
          position: absolute;
          right: 30px;
          top: 10px;
          .coderoom_coderoom_gen {
            font-size: 30px;
            .coderoom_coderoom_gen_icon {
              font-size: 24px;
              color: rgb(187, 17, 17);
            }
          }
        }
        .coderoom_card-content {
          display: flex;
          flex-direction: column;
          padding: 10px;
          margin-left: 10px;
          @include laptop {
            flex-wrap: wrap;
          }
          @include tablet {
            flex-wrap: wrap;
          }
          @include mobile {
            padding: 3px;
            margin-left: 5px;
          }
          .coderoom_lesson-name-text {
            font-size: 20px;
            font-weight: 700;
            color: $black;
          }
          .coderoom_inner-card-content {
            display: flex;
            align-items: center;
            gap: 20px;
            margin-top: 10px;
            @include tablet {
              flex-wrap: wrap;
            }
            @include mobile {
              margin: 2px;
              gap: 3px;
            }

            .coderoom_text-a {
              height: 30px;
              margin-left: 10px;
              display: flex;
              align-items: center;
              color: $black;
              font-weight: 500;
              font-weight: bold;
              @include xtablet{
                margin-left: 0px;
              }
              .coderoom_text-a-t {
                margin-left: 5px;
                color: $black;
              }
              .coderoom_text-a-d {
                margin-left: 5px;
                color: gray;
              }
              .coderoom_icon {
                margin-top: 2px;
                align-items: center;
                justify-content: center;
                color: $black;
                font-size: 20px;
                font-weight: bold;
              }
            }
          }
        }
      }
      .coderoom_result-card-main:hover {
        // border: 1px solid $blue;
        cursor: pointer;
      }
    }
  }
}
.modal_main_box {
  .coderoom_modal_container {
    display: flex;
    flex-direction: column;
    gap: 30px;
    .coderoom_modal_container_header {
      font-size: 30px;
      font-family: $family;
      text-align: center;
      color: rgb(170, 7, 7);
    }
    .coderoom_modal_container_header2 {
      font-size: 20px;
      text-align: center;
    }
    .coderoom_modal_container_footer {
      display: flex;
      width: 100%;
      .coderoom_modal_container_footer_btns {
        display: flex;
        width: 100%;
        justify-content: space-between;
        .coderoom_modal_container_footer_close {
          padding: 10px 30px;
          background-color: $lightGray;
          color: $black;
          cursor: pointer;
          border-radius: 30px;
        }
        .coderoom_modal_container_footer_del {
          padding: 10px 30px;
          background-color: rgb(150, 12, 12);
          color: $lightGray;
          cursor: pointer;
          border-radius: 30px;
        }
      }
    }
  }
}

@import "../../../../Styles/app.scss";
.self_table_container {
  width: 100%;
  background-color: $lightBlue;
  padding: 20px;
  border-radius: 30px;
  @include tablet {
    padding: 0px;
  }
  .self_table_container_header {
    display: grid;
    align-items: center;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    .self_table_container_header_item {
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 20px 0px;
      display: flex;
    }
    .self_table_container_header_search {
      display: flex;
      align-items: center;
      flex: 1;
      @include tablet {
        display: none;
      }
      .self_table_container_header_search_con {
        border-radius: 20px;
        background-color: $lightGray;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 6px;
        width: 100%;
        height: 40px;
        .self_table_container_header_search_el {
          background-color: $lightGray;
          // padding: 20px 10px;
        }
        .self_table_container_header_search_ic {
          background-color: $blue;
          padding: 10px;
          font-size: 33px;
          border-radius: 20px;
          color: $lightGray;
        }
      }
    }
  }
  .self_table_container_body {
    display: flex;
    flex-direction: column;
    .self_table_container_body_row {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      .self_table_container_body_row_item {
        padding: 20px 5px;
        text-align: center;
        position: relative;

        border: 1px solid rgba(128, 128, 128, 0.24);
        @include xtablet {
          padding: 5px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        .self_table_container_body_row_item_btn {
          background-color: $blue;
          color: $lightGray;
          border-radius: 24px;
          padding: 3px 0px;
          width: 80px;
          margin: auto;
          cursor: pointer;
          text-align: center;
          border: 1px solid rgba(128, 128, 128, 0.24);
        }
      }
    }
  }
}

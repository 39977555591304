@import"../../Styles/app.scss";

.portal-main-div{
    display: flex;
    width: 100%;
    height: 70vh;
    text-align: center;
    justify-content: center;


    .min-add-card-div{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 100%;

        .login-register {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 300px;
            padding: 20px;
            background-color: #f7f7f7;
            border-radius: 12px;
            box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
            margin: 0 auto;
          }
          
          .name-title {
            margin: 0;
            font-size: 24px;
            font-weight: bold;
            text-align: center;
            margin-bottom: 10px;
            color: $black;
          }
          
          .second-title {
            margin: 0;
            font-size: 16px;
            text-align: center;
            margin-top: 10px;
            margin-bottom: 20px;
            color: $blue;
          }
          
          .btn-login-a,
          .btn-Register-a {
            width: 100%;
            padding: 12px;
            font-size: 16px;
            border: none;
            border-radius: 6px;
            background-color: #007bff;
            margin: 10px;
            color: #ffffff;
            cursor: pointer;
            transition: background-color 0.3s ease;
          }
          
          .btn-login-a:hover,
          .btn-Register-a:hover {
            background-color: #0056b3;
          }
          
          @media (max-width: 480px) {
            .login-register {
              width: 100%;
            }
          }
          
    }
  
    .loading-page {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 100%;
      
        p {
          font-size: 24px;
          margin-bottom: 20px;
        }
      
        .loader {
          display: inline-block;
          width: 50px;
          height: 50px;
          border: 4px solid #f3f3f3;
          border-top-color: #3498db;
          border-radius: 50%;
          animation: spin 1s linear infinite;
        }
      
        @keyframes spin {
          0% {
            transform: rotate(0deg);
          }
          100% {
            transform: rotate(360deg);
          }
        }
      }

    .class-not-found-min {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 20px;
      
        p {
          font-size: 18px;
          margin-bottom: 10px;
        }
      
        .icon {
          font-size: 48px;
          color: #ccc;
        }
      }

}
@import "../../Styles/app.scss";

.student-classes-main-div {
  display: flex;
  flex-direction: column;
  width: 100%;
  
  max-width: 1000px;
  height: 90vh;
  margin-left: auto;
  margin-right: auto;

  .student-classes {
  
    margin-top: 15px;
    margin-left: 20px;
    padding: 10px;
    width: 97%;
    height: 60%;
    border-radius: 20px;
    flex-direction: column;
    @include tablet{
      margin-top: 10px;
      padding: 5px;
      margin: 0 5px;
    }
    .student-classes_header {
      width: 100%;
      display: flex;
      justify-content: space-between;
      margin-bottom: 20px;
      .student-classes_header_left {
        font-size: 28px;
        color: GRAY;
        font-family: $family;
        font-weight: 500;
      }
      .student-classes_header_right {
        font-size: 18px;
        font-family: $family;
        font-weight: 500;
      }
    }
    .list-of-classes {
      flex-wrap: wrap;
      display: flex;
      flex-direction: column;
      overflow-y: auto;
      overflow: scroll;
      overflow-x: hidden;
      @include tablet{
        width: 100%;
        margin:0 10px ;
      }
      .inner-map-student-class {
        margin-bottom: 10px;
        background-color: $lightBlue;
        padding: 10px;
        cursor: pointer;
        display: flex;
        flex-direction: column;
        width: 97%;
        height: 100px;
        border-radius: 7px;

        .class-name-text {
          color: $blue;
          margin: 5px 10px;
          font-size: 24px;
          font-weight: bold;
        }
        .class-avail-text {
          margin: 5px 10px;
          color: gray;
        }
        .class-total-text {
          margin: 0 10px;
          color: gray;
        }
      }
    }
    .list-of-classes::-webkit-scrollbar {
      display: none;
    }
  }
  .student-classes::-webkit-scrollbar{
    display: none;
  }
}

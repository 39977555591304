@import"../../../../../Styles/app.scss";
 .class-dashboard-div{
    padding: 10px;
    display: flex;
    flex-direction:column;
    width: 100%;
    height: 100%;
@include tablet {
    height: auto;
}
    .top-bar-dashboard{
        width: 98%;
        height: 130px;
        border-radius: 20px;
        background-color: lightblue;
        background-image: url("../../../../../images/pngegg.png");
        background-position: center;
        background-size: contain; /* Fit the image within the container */
        background-repeat: no-repeat; /* Prevent image repetition */
      
      
    }
    .body-dashboard{
        display: flex;
        justify-content: center;
        width: 98%;
        margin-top: 10px;
        height: 300px;
        @include tablet{
            flex-direction: column;
            height: auto;
        }
    
    .shedule-div-a{
        display: flex;
        flex: 1;
        flex-direction: column;
        border-radius: 10px;
        background-color: $lightGray;
        margin: 5px;
        h3{ 
                 margin-top: 10px;
                 margin-left: 15px;
                 color: blue;
        }
        .inner-main-div-shedul{
            padding: 5px;
            display: flex;
            height: 100%;
            justify-content: center;
            flex-direction: column;
            .main-inner-div-map{
                display: flex;
                margin: 3px;
                padding: 5px;
                width: 98%;
                height: 37px;
                background-color: white;
                border-radius: 7px;
                p{
                    color: gray;
                }
            }
            .shedule-more-btn{
                margin-top: 5px;
                color: blue;
            }
        }

    }
    .progress-div-a{
        display: flex;
        flex: 1;
        flex-direction: column;
        margin: 5px;
        border-radius: 10px;
        background-color: $lightGray;
        
        h3{ 
            margin-top: 10px;
            margin-left: 15px;
            color: green;
   }
   .inner-main-div-process{
    padding: 5px;
    display: flex;
    height: 100%;
    justify-content: center;
    flex-direction: column;
    .main-inner-div-map-g{
        display: flex;
        margin: 3px;
        padding: 5px;
        width: 98%;
        height: 37px;
        background-color: white;
        border-radius: 7px;
        p{
            color: gray;
        }
    }
    .shedule-more-btn{
        margin-top: 5px;
        color: blue;
    }
}

    }
    .passed-div-a{
        display: flex;
        flex: 1;
        flex-direction: column;
        margin: 5px;
        border-radius: 10px;
        background-color: $lightGray;
        h3{ 
            margin-top: 10px;
            margin-left: 15px;
            color: red;
   }
   .inner-main-div-passed{
    padding: 5px;
    display: flex;
    height: 100%;
    justify-content: center;
    flex-direction: column;
    .main-inner-div-map-p{
        display: flex;
        margin: 3px;
        padding: 5px;
        width: 98%;
        height: 37px;
        background-color: white;
        border-radius: 7px;
        p{
            color: gray;
        }
    }
    .shedule-more-btn{
        margin-top: 5px;
        color: blue;
    }
}

    }
}
 }
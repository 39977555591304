@import "../../Styles/app.scss";

.forget_container {
  .forget_right-side {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    .forget_inner-content {
      display: flex;
      flex-direction: column;
      gap: 40px;
      width: 500px;
      height: 400px;

      .forget_inner-content-2 {
        font-size: 30px;
        text-align: center;
      }
      .forget_form {
        display: flex;
        flex-direction: column;

        .forget_form_el {
          width: 100%;
          border: 1px solid $blue;
          border-radius: 20px;
          height: 50px;
          padding: 0px 10px;
          font-size: 16px;
          margin-top: 7px;
        }
        .forget_btn {
          background-color: $blue;
          padding: 15px 50px;
          color: $lightGray;
          border-radius: 30px;
          font-size: 18px;
          margin-top: 35px;
        }
      }
    }
  }
}


@import "../../../../../Styles/app.scss";

.explore_preview-main-container {
  margin-top: 10px;
  width: 100%;
  padding: 10px;
  background-color: $lightBlue;
  border-radius: 3px;
  display: flex;
  flex-direction: column;
  height: 80vh;
  padding: 10px;
  overflow-y: scroll;

  .explore_inner-preview-container {
    display: flex;
    flex-direction: column;
    background-color: $lightBlue;
    border-radius: 3px;
    width: 100%;
    height: fit-content;
    .explore_inner-preview-container_header {
      display: flex;
      background-color: $lightGray;
      padding: 10px;
      position: relative;
      @include tablet {
        flex-direction: row;
      }
      .explore_inner-preview-container_header_icon {
        position: absolute;
        right: 20px;
        top: 20px;
      }
      .explore_inner-preview-container_header_img {
        width: 200px;
        height: 200px;
        background-color: $blue;
        @include tablet {
          width: 100px;
          height: 100px;
        }
        .explore_inner-preview-container_header_img_el {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
    .explore_lesson-detail {
      display: flex;
      flex-direction: column;
      flex: 1;
      @include tablet {
        width: 100%;
      }
      .explore_lesson-name-e {
        font-size: 30px;
        font-weight: 700;
        color: $blue;
        text-align: center;
        margin-top: 30px;
      }
      .explore_teacher-name {
        font-size: 12px;
        color: gray;
      }
      .explore_right_container {
        display: flex;
        flex-direction: column;
        .explore_top_div {
          margin-top: 30px;
          margin-left: auto;
          display: flex;
          align-items: center;
          justify-content: flex-end;
          @include tablet {
            flex-direction: column;
          }
          .explore_play-btn {
            margin-left: 10px;
            background-color: $blue;
            border: none;
            color: white;
            width: 200px;
            height: 40px;
            font-size: 1rem;
            margin-left: 5px;
            border-radius: 5px;
            cursor: pointer;
            @include tablet {
              width: 200px;
            }
          }
          .explore_play-btn:hover {
            background-color: darken($color: $blue, $amount: 10);
          }
          .explore_save-btn {
            margin-left: 10px;
            background-color: white;
            border: none;
            color: gray;
            width: 200px;
            height: 40px;
            font-size: 1rem;
            margin-left: 5px;
            border-radius: 5px;
            cursor: pointer;
            @include tablet {
              width: 200px;
            }
          }
        }
        .explore_bottom-div {
          margin: 10px;
          display: flex;
          justify-content: flex-end;
          @include tablet {
            margin: 0px;
          }
          .explore_play-btn {
            margin-left: 10px;
            background-color: $blue;
            border: none;
            color: white;
            width: 100px;
            height: 40px;
            font-size: 1rem;
            margin-left: 5px;
            border-radius: 5px;
            cursor: pointer;
            @include tablet {
              width: 200px;
            }
          }
          .explore_play-btn:hover {
            background-color: darken($color: $blue, $amount: 10);
          }
          .explore_save-btn {
            margin-left: 10px;
            background-color: white;
            border: none;
            color: gray;
            width: 100px;
            height: 40px;
            font-size: 1rem;
            margin-left: 5px;
            border-radius: 5px;
            cursor: pointer;
            @include tablet {
              width: 200px;
            }
          }
        }
      }
    }
    .explore_show-question-min {
      padding: 10px;
      margin-top: 10px;
      background-color: $lightGray;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      .explore_correct-ans {
        display: flex;
        align-items: center;
        font-size: 14px;
        font-weight: 400;
        color: gray;
        input[type="checkbox"] {
          margin-right: 5px;
        }
      }
      .explore_show-question-min_div {
        display: flex;
        flex-direction: column;
        .explore_show-question-min_select {
          padding: 8px 24px;
          border-radius: 20px;
        }
      }
      .explore_selection-div {
        background-color: $blue;
        padding: 10px 20px;
        color: $lightGray;
        border-radius: 20px;
        cursor: pointer;
      }
    }
  }
  .explore_show-question-div {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    margin-top: 10px;

    .explore_single_question {
      background-color: $lightGray;
      padding: 10px;
      margin-top: 10px;
      display: flex;
      flex-direction: column;
      .explore_single_question_header {
        color: gray;
        display: flex;
        justify-content: space-between;
      }
      .explore_single_question_body {
        display: flex;
        align-items: center;
        gap: 10px;
        font-size: 20px;
        font-weight: 700;
        margin: 10px 0px;
        .explore_single_question_body_img {
          width: 100px;
          height: 100px;
          object-fit: cover;
          border-radius: 10px;
        }
      }
      .explore_single_question_footer {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 20px;
        margin: 10px 0px;
        .explore_single_question_footer_option {
          display: flex;
          align-items: center;
          gap: 20px;

          .explore_single_question_footer_option_img {
            width: 50px;
            height: 50px;
            border-radius: 10px;
            object-fit: cover;
          }
        }
      }
    }
  }
  .explore_inner-preview-container::-webkit-scrollbar {
    display: none;
  }
}

.explore_css-1wnsr1i {
  @include tablet {
    width: 100% !important;
  }
}
.explore_preview_modal_container {
  .explore_preview_modal_container_header {
    text-align: center;
    font-weight: 900;
    font-size: 22px;
    margin-bottom: 40px;
  }
  .explore_preview_modal_container_body {
    display: flex;
    flex-direction: column;
    gap: 20px;
    .explore_preview_modal_container_body_item1 {
      display: flex;
      justify-content: space-between;
      .explore_preview_modal_container_body_code {
        display: flex;
        flex-direction: column;
        .explore_preview_modal_container_body_code_up {
          color: $black;
          font-size: 20px;
          display: flex;
          font-family: 600;
          .explore_preview_modal_container_body_code_up_c {
            color: gray;
          }
        }
        .explore_preview_modal_container_body_code_down {
          font-size: 15px;
          color: gray;
        }
      }
      .explore_preview_modal_container_body_code_right {
        font-size: 30px;
        cursor: pointer;
      }
    }
    .explore_preview_modal_container_body_item2 {
      display: flex;
      flex-direction: column;
      .explore_preview_modal_container_body_item2_up {
        color: $black;
        font-size: 18px;
      }
      .explore_preview_modal_container_body_item2_don {
        color: gray;
      }
    }
  }
  .explore_preview_modal_container_footer {
    display: flex;
    justify-content: flex-end;
    margin-top: 30px;
    .explore_preview_modal_container_footer_close {
      background-color: $blue;
      color: $lightGray;
      font-size: 18px;
      padding: 18px 30px;
      border-radius: 20px;
      cursor: pointer;
    }
  }
}

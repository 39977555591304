@import "../../../Styles/app.scss";

.settings_container {
  background-color: $lightGray;
  flex: 1;
  padding: 40px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @include tablet{
    width: 100%;
    padding: 20px;
  }
  .settings_title {
    margin: auto;
    width: 500px;
    font-size: 22px;
    font-weight: 700;
    font-family: $family;
    color: $black;
    margin-bottom: 30px;
    .mylibrary_sidebar_title_menu {
      font-size: 30px;
      display: none;
      cursor: pointer;
      @include xtablet {
        display: block;
      }
    }
  }
  .settings_form {
    width: 500px;
    background-color: $lightBlue;
    margin: auto;
    display: flex;
    flex-direction: column;
    padding: 30px;
    gap: 20px;
    border-radius: 20px;

    @include xtablet {
      width: 100%;
    }

    .settings_title_sec {
      font-weight: 500;
      font-size: 20px;
      color: $black;
    }
    .settings_input_container {
      display: flex;
      flex-direction: column;
      gap: 20px;
      .settings_input_item {
        display: flex;
        flex-direction: column;
        width: 100%;
        .settings_input_label {
          font-size: 15px;
          margin-bottom: 5px;
          color: $black;
        }
        .settings_input_elem {
          border: 1px solid rgba(187, 184, 184, 0.267);
          padding: 17px 10px;
          border-radius: 15px;
          font-size: 19px;
        }
        .settings_input_item_btn {
          background-color: $blue;
          color: $lightBlue;
          text-align: center;
          padding: 15px 10px;
          border: 10px;
          border-radius: 20px;
          width: 50%;
          font-size: 19px;
          margin: auto;
        }
      }
    }
  }
}
.settings_input_item_btns {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
  .settings_input_item_btn {
    background-color: $blue;
    color: $lightBlue;
    text-align: center;
    padding: 15px 10px;
    border: 10px;
    border-radius: 30px;
    width: 50%;
    font-size: 19px;
  }
}
.forget_inner-content {
  display: flex;
  flex-direction: column;
  gap: 40px;width: 100%;
  // width: 500px;
  height: 400px;

  .forget_inner-content-2 {
    font-size: 30px;
    text-align: center;
  }
  .forget_form {
    display: flex;
    flex-direction: column;

    .forget_form_el {
      width: 100%;
      border: 1px solid $blue;
      border-radius: 20px;
      height: 50px;
      padding: 0px 10px;
      font-size: 16px;
      margin-top: 7px;
    }
    .forget_btn {
      background-color: $blue;
      padding: 15px 50px;
      color: $lightGray;
      border-radius: 30px;
      font-size: 18px;
      margin-top: 35px;
    }
  }
}

@import "../../../../Styles/app.scss";
.mylibrary_created {
  width: 80%;
  display: flex;
  flex-direction: column;

  .mylibrary_created_item {
    display: flex;
    width: 100%;
    padding: 10px;

    background-color: $lightGray;
    .mylibrary_created_item_img {
      width: 70px;
      height: 70px;
      margin-right: 20px;
      border-radius: 50px;
      object-fit: cover;
      @include tablet {
        margin-right: 5px;
        display: none;
      }
    }
    .mylibrary_created_item_icon {
      width: 70px;
      height: 70px;
      margin-right: 20px;
      border-radius: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
      @include tablet {
        margin-right: 5px;
      }
      .mylibrary_created_item_icon_el {
        font-size: 40px;
        color: gray;
      }
    }
    .mylibrary_created_item_det {
      position: relative;
      flex: 1;
      .mylibrary_created_item_det_dots {
        position: absolute;
        top: 0px;
        right: 0px;
      }
      .mylibrary_created_item_det_header {
        font-size: 12px;
        color: lighten($color: $black, $amount: 20);
        margin-bottom: 4px;
      }
      .mylibrary_created_item_det_name {
        color: $black;
        font-weight: 700;
        font-size: 20px !important;
        margin-bottom: 4px;
      }
      .mylibrary_created_item_det_det {
        display: flex;
        gap: 20px !important;
        align-items: center;
        margin-bottom: 4px;
        font-size: 14px !important;
        color: lighten($color: $black, $amount: 20);
        @include tablet {
          gap: 5px;
        }
      }
    }
  }
  .mylibrary_created_item_det_name_line {
    height: 2px;
    background-color: rgba(204, 198, 198, 0.288);
    width: 70%;
    margin-right: auto;
    margin-left: auto;
    margin-bottom: 16px;
  }
}

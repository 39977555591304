@import "../../../../../../Styles/app.scss";

.div-of-div{
    overflow-y: auto;
    overflow:scroll;
    overflow-x:hidden;



    .main-div-quizes-d{
        display: flex;
        padding: 10px;
        justify-content: center;
        flex-wrap: wrap;
        @include tablet{
         padding:5px;
        }
        
        

        .inner-class-div-a-d{
            flex-wrap: wrap;
            display: flex;
            justify-content: space-between;
            margin: 10px;
            width: 80%;
            height: 60px;
            @include tablet{
                width: 100%;
                height: auto;
            }
            // border: 1px solid $blue;
            border-radius: 5px;
            background-color: rgb(99, 248, 174);
            box-shadow: rgba(0, 0, 0, 0.10) 0px 3px 8px;

            .inner-class-bar-a-d{
                padding: 5px;
                display: flex;
                width: 80%;
                cursor: pointer;
                .single-class-name-a-d{
                       font-size: 18px;
                       margin-left: 10px;
                       color: $blue;
                       font-weight: bold;
                }
                
            }
            .show-student-text-a-d{
                padding: 5px;
                margin-left: 10px;
                font-size: 14px;
                color: gray;
                font-weight: 400;

            }
            .show-student-text-b-d{
                padding: 5px;
                margin-left: 10px;
                font-size: 14px;
                color: gray;
                font-weight: 400;

            }
        }
        .menuItem {
            font-size: 12px;
          }
    }
    .main-div-quizes-d::-webkit-scrollbar{
        display: none;
    }
}
.div-of-div::-webkit-scrollbar{
    display: none;
}
@import "../../../Styles/app.scss";

.container_timer {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 400px;
  flex-direction: column;
  .container_timer_number {
    font-size: 190px;
    font-family: $family;
    font-weight: bold;
    text-align: center;
    text-shadow: 0px 0px 18px black;
    color: $lightBlue;
  }
  .container_timer_btn {
    all: unset;
    width: 200px;
    height: 40px;
    line-height: 40px;

    text-align: center;
    background-color: $lightBlue;
    color: $black;
    cursor: pointer;
    border-radius: 10px;
    border: 1px solid $black;
  }
}

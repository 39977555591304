@import "../../../Styles/app.scss";

.quiz-main-div {
  display: flex;
  flex-direction: column;
  background-color: $lightBlue;
  border-radius: 20px;
  padding: 20px;
  border: 3px solid $blue;
  font-family: $family;
  .loading-toast {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.5);
    color: #fff;
    padding: 16px;
    font-size: 18px;
    border-radius: 4px;
    z-index: 9999;
  }
  
  .blur-page {
    filter: blur(4px);
    pointer-events: none;
  }
  .A-h2 {
   text-align: center;
    font-family: $family;
    color: $blue;
    line-height: 40px; 
  }

  .form-1 {
    margin-top: 15px;
    display: flex;
    flex-direction: column;

    .div-2 {
      display: flex;
      margin-top: 10px;

      .selectbook-title {
        color: $black;
        font-weight: 700;
        color: $blue;
        font-size: 18px;
      }
      .book-name {
        span {
          margin: 5px;
          font-weight: 300;
          padding: 5px;
          cursor: pointer;
        }
        span:hover {
          border-bottom: 1px solid $blue;
        }
        .selected {
          color: rgb(0, 68, 255);
          border-bottom: 1px solid $blue;
        }
      }
    }
    .grade {
      display: flex;
      margin-left: 10px;

      .grad-title {
        color: $black;
        font-weight: 700;
        color: $blue;
        font-size: 18px;
      }
      .opt-label {
        display: inline-block;
        text-align: center;
        line-height: 25px;
        height: 25px;
        width: 25px;
        margin-left: 5px;
        font-weight: 700;
        cursor: pointer;
      }
      .opt-label:hover {
        color: $blue;
      }
      .selected-grd {
        border: 1px solid $blue;
        border-radius: 50%;
        color: $blue;
      }
      input {
        all: unset;
        border: none;
        outline: none;
      }
    }
    .div-3 {
      display: flex;
      flex-direction: row;
      margin-top: 15px;
      .chapter-add {
        display: flex;
        text-align: center;
        align-items: center;

        h3 {
          text-align: center;
          font-family: $family;
          
          color: $blue;
          line-height: 40px;
        }
        .selections {
          margin-left: 30px;
          height: 30px;
          text-align: center;
          font-size: 16px;
          border-radius: 5px;
        }
      }
      .enter-lesson {
        display: flex;
        margin: 5px;
        margin-left: 20px;
        align-items: center;
        flex-wrap: wrap;
        input {
          width: 270px;
          height: 35px;
          text-align: center;
          font-size: 16px;
          border-radius: 10px;
        }
      }
      .select-lesson-image {
        margin: auto 2px;
        margin-left: 30px;
        text-align: center;
        align-items: center;
        font-size: 30px;
      }
      

    }
    .modal{
      display: flex;
      .btn-ab-1{
        margin: 10px;
      }
    }
    

    .qustion-settings{
      display: flex;
      flex-direction: column;
      margin-top: 15px;

      .selection-div{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-around;
        margin-top: 20px;
        .btn-allselect{
          border-radius: 10px;
          width: 80px;
          height: 30px;
          background-color: $lightGray;
          color: $blue;
          font-size: 16px;
          border: 1px solid $blue;
        }
        .btn-allselect:hover{
          background-color: rgba(247, 244, 244, 0.863);
        }
        .btn-allselect-selected{
          background-color: $blue;
          color: white;
        }
        label{
          color: $blue;
          font-weight: 700;
        }
        .select{
          margin-left: 10px;
          width: 70px;
          height: 30px;
          border-radius: 5px;
          font-size: 16px;
        }

      }
      .total-info{
        display: flex;
       margin: 20px;  
       margin-top: 30px;
       justify-content: space-around;
       p{
         font-weight: 700;
       }
      }
    }
    .show-question-div{
      display: flex;
      flex-direction: column;
      
      .show-q-name{
    font-family: $family;
    color: $blue;
    line-height: 40px;
    font-weight: 700;
    
      }
    }
    .end-div-btn {
      display: flex;
      justify-content: flex-end;
      margin-top: 20px;
    }
    
    .btnn {
      background-color: $blue;
      color: #fff;
      padding: 10px 20px;
      border: none;
      border-radius: 10px;
      cursor: pointer;
      font-size: 16px;
      margin-right: 20px;
    }
    
    .btnn:hover {
      background-color: blue;
    }
    
    .btnn:last-child {
      margin-right: 0;
    }

  }
}
#vertical-tabpanel-2 {
  width: 85%;
}

.main-div {
  display: flex;
  height: auto;
  width: 100%;
  .MuiBox-root{
    width: 100% !important;
    margin: 0px;
    padding: 0px;
  }
}
